html{
  height:100%;
}

body{
  margin:0;
  font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:#212529;
  height: 100%;
  background: black;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
